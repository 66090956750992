import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import "./404.scss"

const NotFoundPage = () => (
  <Layout customClassName="404">
    <SEO title="404: Not found" />
      <h1>4-OH-4</h1>
      <p>Move along, nothing to see here.</p>
  </Layout>
)

export default NotFoundPage
